<template>
  <!-- 
    画面: プランカテゴリ編集
    用途: プランカテゴリを更新または削除する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("planCategoryEditPage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <!-- #region 入力フィールド -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("planCategoryEditPage.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.name.$dirty &&
                      !$v.edit.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("planCategoryEditPage.name") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.name.$dirty &&
                      !$v.edit.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("planCategoryEditPage.name"),vMax: $v.edit.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("planCategoryEditPage.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="edit.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("planCategoryEditPage.description"),vMax: $v.edit.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="icon cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="icon cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              size="md"
              @click="onBackClicked()"
            >
              <i class="icon cil-arrow-left mr-2"></i>
              {{ $t("common.back") }}
            </CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import RoomTypeListTable from "../../components/RoomTypeListTable";
// import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";
import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {

  name: "PlanCategoryEdit",

  components: {
    // RoomTypeListTable
    SuccessModal,
    ErrorModal,
    DeleteConfirmModal
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? 'inactive' : 'active'
    },
    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    },
  },
  
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
    deleteSuccessModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },


  data() {
    return {

      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message


      //#region Input

      edit: {
        name: "",
        description: "",
      },

      //#endregion Input


      //#region Response data

      response: [],

      //#endregion Response data

    }
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        description: {
          maxLength: maxLength(1000)
        },
      }
    };
  },

  methods: {

    //#region Event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDelete();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.edit.$invalid ?
        this.$v.edit.$touch() :
        this.reqPut();

      // this.successModal = true;
    },

    //#endregion Event


    //#region Request

    /** プランカテゴリ一覧取得 */
    reqGet() {

      //TODO:開発用
      const datas = [
        {
          id: "1",
          code: "plc01",
          name: "ファミリー向けプラン",
          description:"小さい子供連れの家族でも楽しめるプランです。",
          displayOrder:1,
          language:"ja",
          status: true,
        },
        {
          id: "2",
          code: "plc02",
          name: "夏限定プラン",
          description:"夏限定の食事メニューや施設が利用できるプランです。",
          displayOrder:2,
          language:"ja",
          status: true,
        },
        {
          id: "3",
          code: "plc03",
          name: "通年プラン",
          description:"年間を通していつでも利用できるプランです。",
          displayOrder:3,
          language:"ja",
          status: true,
        },
      ]
      
      const planCategoryId = this.$route.params.planCategoryId;
      const editObj = datas.find((e) => e.id === planCategoryId);

      // 取得データを流し込み
      this.edit.name = editObj.name;
      this.edit.description = editObj.description;

      this.loading = false;

      //TODO:API完成後にコメントアウト外す
      // const planCategoryId = this.$route.params.planCategoryId;
      // const url = `/rest/hotel/${this.uid}/planCategory/${planCategoryId}`;

      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${this.$keycloak.token}` },
      //   })
      //   .then((resp) => {
      //     const data = resp.data;
      //     console.log(data);

      // // 取得データを流し込み
      // this.edit.name = data.name;
      // this.edit.description = data.description;

      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.PassError = err.response.status;
      //     this.loading = false;
      //   });
    },


    /**
     * delete
     */
    reqDelete() {
      this.deleteConfirmModal = false;
      this.successModal = true;

      const planCategoryId = this.$route.params.planCategoryId;
      const url = `/rest/hotel/${this.uid}/planCategory/${planCategoryId}`;
      console.log(url);

      // TODO: API開発後に実装する

      // axios
      //   .delete(url, {
      //     headers: { "Accept-Language": this.$i18n.locale },
      //   })
      //   .then((resp) => {
      //     console.log(JSON.stringify(resp.data));

      //     this.successModal = true;
      //     this.editImageModal = false;
      //     this.selectedCategory == 0
      //       ? this.getAllImages()
      //       : this.getDefImg(this.selectedCategory);
      //   })
      //   .catch((err) => {
      //     this.errorMessage = err + "'  post to facility '";
      //     this.errorModal = true;
      //   });
    },

    reqPut() {
      this.successModal = true;

      const planCategoryId = this.$route.params.planCategoryId;
      const url = `/rest/hotel/${this.uid}/planCategory/${planCategoryId}`;
      const body = this.edit;
      console.log(url);
      console.log(body);

      // TODO: API開発後に実装する

    },

    //#endregion Request


    //#region Method

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method

  },
  beforeMount() {
    this.reqGet();
  },
}
</script>
